import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./utility/ScrollToTop";

import "normalize.css";
import "./styles/main.scss";
import Metrics from "./utility/Metrics";

const equipmentRentals = lazy(() => import("./pages/equipmentRentals"));
const equipmentHowItWorks = lazy(() => import("./pages/equipmentHowItWorks"));
const Store = lazy(() => import("./pages/Store"));
const Product = lazy(() => import("./pages/Product"));
const Account = lazy(() => import("./pages/Account"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const WishlistOverview = lazy(() => import("./pages/WishlistOverview"));
const WishlistReceipt = lazy(() => import("./pages/WishlistReceipt"));

function App({ apikey, ready }) {
    // Sync the API key as an Authorization header for all requests
    useEffect(() => {
        if (apikey) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${apikey}`;
            $.ajaxSetup({
                headers: {
                    Authorization: `Bearer ${apikey}`
                }
            });
        } else {
            delete axios.defaults.headers.common["Authorization"];
            $.ajaxSetup({
                headers: {}
            });
        }
    }, [ apikey ]);

    return (
        <Router>
            <Metrics>
                <ScrollToTop>
                    <Nav />

                    <div className="page-content">
                        {ready && <Suspense fallback={<div className="root-suspense"/>}>
                            <Switch>
                                <Route path="/" exact component={equipmentRentals}/> } />
                                <Route path="/equipment/rentals" exact component={equipmentRentals}/>
                                <Route path="/equipment/rentals/:slug" component={Store}/>
                                <Route path="/equipment/how-it-works" exact component={equipmentHowItWorks}/>
                                <Route path="/contact/" exact component={() => {
                                    // This should be a dead link now, but in case anything tries to hit
                                    // this path, direct it back to the main Root site
                                    window.location = "http://rootnyc.com/contact/";
                                    return null;
                                }}/>
                                <Route path="/products/:slug" component={Product}/>
                                <Route path="/account/password/forgot" exact component={ForgotPassword}/>
                                <Route path="/account/password/reset" exact component={ResetPassword}/>
                                <Route path="/account" component={Account}/>
                                <Route path="/wishlist/" exact component={Wishlist}/>
                                <Route path="/wishlist-overview/" exact component={WishlistOverview}/>
                                <Route path="/wishlist/:ref/receipt" exact component={WishlistReceipt}/>
                                <Route render={() => <div className="error-404"><h1>Page Not Found</h1></div>}/>
                            </Switch>
                        </Suspense>}
                    </div>

                    <Footer />
                </ScrollToTop>
            </Metrics>
        </Router>
    );
}

const mapStateToProps = state => ({
    ready: state.app.initialized,
    isLoggedIn: state.auth.isLoggedIn,
    apikey: state.auth.apikey
});

export default connect(mapStateToProps)(App);
