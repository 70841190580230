import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const Metrics = ({ children, location: { pathname, search } }) => {
    useEffect(() => {
        window.gtag("event", "page_view", {
            page_path: pathname + search
        });
    }, [ pathname, search ]);

    return children;
};

export default withRouter(Metrics);
