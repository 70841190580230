import $ from "jquery";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../img/logo.svg";
import LogoBlack from "../img/logo-black.svg";
import SearchIcon from "../img/icons/search.svg";
import UserIcon from "../img/icons/user.png";
import UserIconWhite from "../img/icons/user-white.png";
import CartIcon from "../img/icons/cart.svg";
import CartIconWhite from "../img/icons/cart-white.svg";
import SearchIconWhite from "../img/icons/search-white.svg";
import cx from "classnames";
import NavGroup from "../components/nav/group";
import { NavLink as Hyper } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import FlashMessage from "./FlashMessage";
import navStructure from "../navigation";
import sanitizeHtml from "sanitize-html";

let navSearchTimeout = undefined,
    navFirstLoad = true;

const Nav = ({ listItems }) => {
    const [ searchText, setSearchText ] = useState(""),
        [ topOfPage, setTopOfPage ] = useState(true),
        [ navOpen, setNavOpen ] = useState(false),
        [ searchOpen, setSearchOpen ] = useState(false),
        [ searchResults, setSearchResults ] = useState([]),
        [ moreResults, setMoreResults ] = useState(false),
        history = useHistory();

    const navClasses = cx({
        "c-nav-overlay": true,
        "js-nav-overlay": true,
        "is-visible": navOpen
    });

    const toggleNav = () => {
        setNavOpen(wasNavOpen => !wasNavOpen);
    };

    const closeSearch = () => {
        setSearchOpen(false);
        setSearchResults([]);
        $("#site-header-search-input").val("");
        $(document).off("keydown", closeSearchWithKeyboard);
    };

    const closeSearchWithKeyboard = (e) => {
        if (e.keyCode === 27) {
            closeSearch();
        }
    };

    const openSearch = () => {
        setSearchOpen(true);
        $("#site-header-search-input").focus();
        $(document).on("keydown", closeSearchWithKeyboard);
    };

    const searchFormChange = (e) => {
        const value = e.target.value.replace(/^ */, "").replace(/ *$/, "");

        setSearchText(value);
        clearTimeout(navSearchTimeout);

        navSearchTimeout = setTimeout(() => {
            if (value.length > 2) {
                $.ajax({
                    type: "POST",
                    url: `${process.env.REACT_APP_API_URL}api/product/search-products?page=1`,
                    data: { search: value }
                }).always(function(response) {
                    const searchTerms = value.split(" ");

                    let products = [];

                    if (typeof response.products === "object") {
                        products = response.products;

                        products.forEach((product) => {
                            searchTerms.forEach((term) => {
                                const regex = [
                                    new RegExp(`^()(${term})()$`, "gi"), // start and end
                                    new RegExp(`^()(${term})([^a-zA-Z0-9])`, "gi"), // start
                                    new RegExp(`([^a-zA-Z0-9])(${term})()$`, "gi"), // end
                                    new RegExp(`([^a-zA-Z0-9])(${term})([^a-zA-Z0-9])`, "gi") // middle
                                ];

                                regex.forEach((re) => {
                                    product.title = product.title.replace(re, "$1<strong>$2</strong>$3");
                                });
                            });
                        });

                        setMoreResults(response.more);
                        setSearchResults(products);
                    }
                });
            }
        }, 250);
    };

    const topOfPageCheck = () => {
        setTopOfPage($(window).scrollTop() === 0);
    };

    const viewMore = (searchText) => {
        closeSearch();

        if ($("#store-tab").length) {
            $("#store-tab").trigger("update-search", [ searchText ]);
        }
    };

    if (navFirstLoad) {
        navFirstLoad = false;
        topOfPageCheck();
        $(window).on("scroll resize orientationchange", topOfPageCheck);
    }

    return (
        <nav className="navbar">
            <div
                className={ "nav-search-underlay " + (searchOpen ? "visible" : "") }
                onClick={ closeSearch }>
            </div>

            <div className="header">
                <header className={ `c-site-header ${history.location.pathname === "/" ? "page-home" : "page-" + history.location.pathname.replace(/^\//, "").replace(/\/$/, "").replace(/\//g, "-")} ${topOfPage ? "top-of-the-page" : ""}` }>
                    <div className="nav-left">
                        <Hyper to="/" className="c-site-logo js-site-logo">
                            <img src={ LogoBlack } alt="Root NYC logo" />
                            <img className="light-logo" src={ Logo } alt="" />
                        </Hyper>

                        <div className="c-nav-toggle js-nav-toggle" onClick={ toggleNav }>
                            <div className="toggle-line"></div>
                            <div className="toggle-line"></div>
                            <div className="toggle-line"></div>
                            <span className="sr-only">Open navigation</span>
                        </div>
                    </div>

                    <div className="nav-right">
                        <button className="nav-search" onClick={ openSearch }>
                            <img src={ SearchIcon } alt="Search" />
                            <img className="light-icon" src={ SearchIconWhite } alt="" />
                        </button>

                        <Hyper className="nav-account" to="/account">
                            <img src={ UserIcon } alt="Account" />
                            <img className="light-icon" src={ UserIconWhite } alt="" />
                        </Hyper>

                        <Hyper className="nav-cart" to="/wishlist">
                            <img src={ CartIcon } alt="Wishlist" />
                            <img className="light-icon" src={ CartIconWhite } alt="" />
                            <div className={ "nav-cart-items " + (typeof listItems !== "undefined" && Object.keys(listItems).length ? "active" : "") }></div>
                        </Hyper>
                    </div>

                    <div className={ "c-site-header-search " + (searchOpen ? "open" : "") }>
                        <div className="c-site-header-search-inner">
                            <div className="c-site-header-search-inner-icon">
                                <img src={ SearchIconWhite } alt="Search for Products" />
                            </div>

                            <input
                                id="site-header-search-input"
                                className="c-site-header-search-inner-input"
                                placeholder="Search"
                                onChange={ searchFormChange }
                            />

                            <button
                                className="c-site-header-search-inner-close"
                                onClick={ closeSearch }>

                                <div className="c-site-header-search-inner-close-inner">
                                    Close
                                </div>
                            </button>

                            <div className={ "c-site-header-search-inner-results " + (searchResults.length && searchText !== "" && searchOpen ? "visible" : "") }>
                                <div className="c-site-header-search-inner-results-inner">
                                    <div className="c-site-header-search-inner-results-inner-heading-column">
                                        <h1>Search Results</h1>
                                    </div>

                                    <div className="c-site-header-search-inner-results-inner-list-column">
                                        <ul>
                                            {
                                                searchResults.length > 0 && searchResults.map((result) =>
                                                    <li
                                                        key={ result.url }
                                                        onClick={ closeSearch }>

                                                        <Link to={ result.url } dangerouslySetInnerHTML={{ __html: sanitizeHtml(result.title) }}></Link>
                                                    </li>
                                                )
                                            }
                                        </ul>

                                        {
                                            searchResults.length > 0 &&

                                            <Link
                                                className="c-site-header-search-inner-results-inner-list-column-more"
                                                to={ "/equipment/rentals/all?search=" + encodeURIComponent(searchText) }
                                                onClick={ () => { viewMore(searchText); } }>

                                                View All
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <FlashMessage/>
            </div>

            <section className={ navClasses }>
                <div className="c-site-header">
                    <div className="nav-left">
                        <Hyper to="/">
                            <img src={ Logo } alt="Root NYC Logo" />
                        </Hyper>
                    </div>

                    <div className="nav-right">
                        <button className="close-button" onClick={ toggleNav }>
                            <span className="sr-only">Close navigation</span>
                        </button>
                    </div>
                </div>

                <div className="c-nav-overlay-links">
                    <div className="c-nav-overlay-links-inner">
                        <nav className="c-site-nav">
                            {navStructure.groups.map(group =>
                                <NavGroup clickHandle={ toggleNav} mainNavOpen={navOpen} {...group}/>
                            )}
                        </nav>

                        <nav className="c-secondary-nav">
                            <li><Hyper onClick={ toggleNav } to="/account/">My Account</Hyper></li>
                            <li><Hyper onClick={ toggleNav } to="/contact/">Contact</Hyper></li>
                            <li><Hyper onClick={ toggleNav } to="/careers/">Careers</Hyper></li>
                        </nav>

                        <div className="social-links">
                            <a href="https://www.facebook.com/ROOT.NYC.BKN" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon icon={ faFacebookF } />
                            </a>

                            <a href="https://www.linkedin.com/company/root-studios-nyc-/" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon icon={ faLinkedinIn } />
                            </a>

                            <a href="https://twitter.com/rootstudios" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon icon={ faTwitter } />
                            </a>

                            <a href="https://www.instagram.com/rootstudios/" rel="noopener noreferrer" target="_blank">
                                <FontAwesomeIcon icon={ faInstagram } />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </nav>
    );
};

const mapStateToProps = (state) => {
    return {
        listItems: state.wishlist.items
    };
};

export default connect(mapStateToProps)(Nav);
